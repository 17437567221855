import * as React from "react"
import {navigate} from "gatsby"
import {Button, Container, ThemeProvider} from "react-bootstrap";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import * as styles from "./404.module.scss";
import AnchorArrowSVG from "../svg/anchorArrow.svg";
import {Helmet} from "react-helmet";

export default function NotFoundPage() {
    return <>
        <Helmet>
            <title>Page not found - Recoded Recruitment</title>
        </Helmet>
        <ThemeProvider>
            <header>
                <Header/>
            </header>
            <Container fluid="xxl" style={{paddingTop: 150}}>
                <main style={{minHeight: "40vh"}}>
                    <div className={styles.notFoundContainer}>
                        <div className={styles.textContainer}>
                            <span className={styles.statusCode}>404</span>
                            <span className={styles.info}>Oops, what’s happened here!</span>
                            <span className={styles.nextStep}>
                            We have recently updated and refined our website which has caused some of our old <br/>
                            pages to vanish. Why not head home to restart your journey.
                        </span>
                        </div>
                        <Button className={styles.headHome} onClick={() => navigate('/')}>
                            <span className={styles.text}>Head home</span>
                            <AnchorArrowSVG className={styles.arrow}/>
                        </Button>
                    </div>
                </main>
                <footer>
                    <Footer/>
                </footer>
            </Container>
        </ThemeProvider>
    </>;
}
